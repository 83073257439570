/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.shouldUpdateScroll = ({ prevRouterProps, routerProps}) => {
  // Don't update the scroll if there's a hash
  // We will handle this with react-scrollable-anchor
  return !routerProps.location.hash;
}